<template>
  <div class="content-wrapper px-3">
    <div>
      <section class="mx-auto p-0 w-md-50">
        <empty-list
          v-if="providers.length === 0"
          text="Ainda não há prestadores sendo validados"
        />
        <section class="mt-3 mb-4" v-else>
          <h5>Avaliação dos prestadores</h5>
          <div v-for="(ranking, index) in providers" :key="index" class="mb-2">
            <b-card
              no-body
              class="d-md-flex flex-row w-100 justify-content-between mb-2 p-2 pointer"
              @click="handleOpenValidation(ranking)"
            >
              <div
                class="p-2 w-100"
              >
                <strong
                  v-if="ranking && ranking.provider"
                  class="w-100 mb-1 d-block"
                >
                  {{ ranking.provider.social_reason }}
                  
                </strong>
                
                <b-badge :variant="getStatus(ranking.status).variant" class="d-table">
                    {{ getStatus(ranking.status).label }}
                </b-badge>
                <div
                  class="d-flex justify-content-between align-items-start gap-3"
                >
                  <div class="item d-flex w-100 align-items-center">
                    <b-progress style="height: 8px" :max="100" class="w-100">
                      <b-progress-bar
                        :value="ranking.progress || 0"
                        variant="primary"
                      />
                    </b-progress>
                    <span class="ml-2">{{ ranking.progress || 0 }}%</span>
                  </div>

                  <div class="d-flex align-items-start gap-3">
                    <section
                      class="d-flex align-items-start gap-4 w-100 justify-content-between  "
                    >
                      <div
                        class="text-muted item text-right d-flex gap-1"
                        v-if="ranking.score"
                      >
                        <strong>{{ ranking.score.toFixed(1) || 0 }}</strong>
                        <span> pontos</span>
                      </div>
                    </section>
                    <b-badge variant="danger" v-if="ranking.need_attentions">
                      <b-icon-exclamation-circle class="mr-1" />
                      <strong>{{ ranking.need_attentions }}</strong>
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      providers: [],
      homologation_id: "",
      service_id: "",
      current_step: "",
      enable_validation_button: "",
    };
  },
  computed: {
    ...mapGetters(["is_mobile", "user"]),
  },
  methods: {
    ...mapActions([
      "get_service_validation_admin",
      "get_service_validation_ranking",
      "get_service_scope",
      "show_toast"
    ]),
    sliceAvatar() {
      const length = this.serviceScope.notifications.length;
      if (length > 4) {
        return length - 4;
      }
      return 0;
    },

    getServiceValidation() {
      this.loading = true;
      this.get_service_validation_admin({ service_id: this.service_id }).then(
        ({ steps, current_step, enable_validation_button }) => {
          this.steps = steps;
          this.current_step = current_step;
          this.enable_validation_button = enable_validation_button;
        }
      );
      this.get_service_validation_ranking({ service_id: this.service_id }).then(
        ({ data }) => {
          const results = Array.from(data.results);
          this.providers = results;
          this.loading = false;
        }
      );
    },
    handleOpenValidation(ranking){
      if (ranking.status == 0){
        this.show_toast({
          message: "O prestador ainda não iniciou a validação dos módulos.",
          type: "error",
        });
      } else {
        this.$router.push(`/homologation/${this.homologation_id}/service/${this.service_id}/${user.role}/validation/${ranking.provider.id}/${ranking.id}`);
      }
    },
    getStatus(status = 0) {
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não iniciado";
          break;
        case 1:
          variant = "secondary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizado";
          break;
      }
      return {
        variant,
        label,
      };
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.homologation_id = this.$route.params.homologation_id;
    this.getServiceValidation();
  },
};
</script>
